<template>
  <el-drawer
    :visible="visible"
    size="80%"
    custom-class="gles-drawer"
    :with-header="false"
    @update:visible="$emit('update:visible', $event)"
  >
    <div class="drawer-wrapper">
      <div class="drawer-operate-btn">
        <el-button type="primary" @click="handleEdit">
          {{ $t('lang.gles.common.edit') }}
        </el-button>
        <el-button
          type="danger"
          :disabled="formModel.templateStatus === 0"
          @click="handleDelete"
        >
          {{ $t('lang.gles.common.delete') }}
        </el-button>
        <i class="el-icon-close" @click="$emit('update:visible', false)" />
      </div>
      <h3 class="title">
        {{ $t('lang.gles.workflow.workPositionInfo') }}
      </h3>
      <work-position
        :row-detail.sync="formModel"
        :table-data.sync="workPositions"
        :mode="mode"
        @update:table-data="workPositions = $event"
      />
      <work-type
        :table-data.sync="workTypes"
        :notice-list.sync="formModel.informConfigs"
        :mode="mode"
        style="margin-top: 10px"
        @update:table-data="workTypes = $event"
      />
    </div>
  </el-drawer>
</template>
<script>
import { getEditBaseFormData } from '../data'
import { mapState } from 'vuex'
import WorkType from './workType.vue'
import WorkPosition from './workPosition.vue'
import addMixins from '@/mixins/addMixins'
import myMixins from '../mixins'

export default {
  name: 'WorkPositionConfigDetail',
  components: {
    WorkType,
    WorkPosition
  },
  mixins: [addMixins, myMixins],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'detail'
    },
    /**
     * 可放子容器 默认是
     */
    rowDetail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      extendFormConfig: {
        isNeedBtn: false,
        isGroup: false
      },
      formModel: {
        selectedWorkPositionList: []
      },
      noticeFormModel: {
        // 默认关闭
        noticeStatus: 1,
        noticeTiming: 2,
        noticeThirdInterface: ''
      },
      // 前端分页：当前页工位配置列表
      workPositions: [],
      // 作业类型列表
      workTypes: []
    }
  },
  computed: {
    ...mapState('workflow', [
      'robotTypeList',
      'robotWorkTypeList',
      'workPositionTypeList'
    ]),
    baseFormData() {
      const row = this.rowDetail || { ...this.formModel }
      return getEditBaseFormData(this, { ...row })
    }
  },
  created() {
    this.getDetail({ id: this.rowDetail.id })
    this.initSelectList()
  },
  methods: {
    initSelectList() {
      if (!this.robotWorkTypeList?.length) {
        this.$store.dispatch('workflow/getRobotWorkTypeList')
      }
      if (!this.workPositionTypeList?.length) {
        this.$store.dispatch('workflow/getWorkPositionTypeList')
      }
      if (!this.robotTypeList?.length) {
        this.$store.dispatch('workflow/getRobotTypeList')
      }
    },
    /**
     * 获取工位详情
     */
    async getDetail(params) {
      const { data, code } = await this.$httpService(
        this.$apiStore.workflow('queryPositionConfigDetail'),
        params
      )
      if (code) return
      const { items: workTypes } = data
      this.formModel = data

      // 单据类型展示格式话
      workTypes.forEach((item) => {
        item.robotTypeName = this.getRobotTypeName(item)
        item.workTypeName = this.getWorkTypeName(item)
        item.isSelection = false
      })
      this.workTypes = workTypes

      // 工位位置
      this.workPositions = [
        {
          id: data.id,
          areaId: data.areaId,
          productionLineId: data.productionLineId,
          deviceId: data.deviceId,
          workPositionId: data.workPositionId,
          workPositionName: data.workPositionName,
          workPositionType: data.workPositionType
        }
      ]

      // 工位位置选中信息回显
      this.formModel = {
        workPositionType: data.workPositionType,
        selectedWorkPositionList: this.getEchoSelectedWorkPositionList(data),
        workPositionName: data.workPositionName,
        informConfigs: data.informConfigs || []
      }
    },
    /**
     * 回显选中的工位位置
     */
    getEchoSelectedWorkPositionList(data) {
      // 设备货位
      if (data.workPositionType === 1) {
        return [
          `${data.areaId},${data.productionLineId},${data.deviceId}`,
          `${data.workPositionId},${data.workPositionCode}`
        ]
      } else if (data.workPositionType === 2) {
        return [
          `${data.areaId}`,
          `${data.workPositionId},${data.workPositionCode}`
        ]
      } else {
        return []
      }
    },
    /**
     * 编辑
     */
    handleEdit() {
      this.$EventBus.$emit('page:updateCom', {
        currentCom: 'WorkPositionConfigAdd',
        mode: 'edit',
        row: this.rowDetail
      })
    },
    /**
     * 删除
     */
    handleDelete(row) {
      // 二次确认
      const confirmMsg = this.$t('lang.gles.workflow.workPosition.checkMsg1')
      if (confirmMsg) {
        this.$confirm(confirmMsg, '', {
          confirmButtonText: this.$t('lang.gles.common.confirm'),
          cancelButtonText: this.$t('lang.gles.common.cancel'),
          type: 'warning'
        }).then(() => {
          this.deleteWorkPositionConfig(row)
        })
        return
      }
    },
    /**
     * 接口删除
     */
    async deleteWorkPositionConfig() {
      const { code } = await this.$httpService(
        this.$apiStore.workflow('deleteWorkPositionConfig'),
        {
          ids: [this.rowDetail.id]
        }
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.deleteSuccessfully'))
      this.$emit('update:visible', false)
      this.$parent.doSearch()
    }
  }
}
</script>
<style lang="scss" scoped>
.el-table__header-wrapper {
  overflow: auto;
}
/deep/ .el-drawer__header {
  margin-bottom: -30px;
}
/deep/ .el-drawer__body {
  overflow-y: scroll;
}
</style>
