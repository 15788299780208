export default {
  methods: {
    /**
     * 机器人类型名称
     * @param {*} item
     */
    getRobotTypeName(item) {
      return (
        this.robotTypeList?.find((t) => t.value === item.robotType)?.label ?? ''
      )
    },
    /**
     * 单据类型名称
     * @param {*} item
     */
    getWorkTypeName(item) {
      return (
        this.robotWorkTypeList?.find((t) => parseInt(t.value) === item.workType)
          ?.label ?? ''
      )
    }
  }
}
