<template>
  <m-dialog
    :visible="visible"
    :title="$t('lang.gles.workflow.addWorkType')"
    :is-need-footer="false"
    @closed="handleReset"
    @input="handleReset"
  >
    <m-form
      ref="myForm"
      :form-data="baseFormData"
      label-position="top"
      :label-width="120"
      :extend-config="extendFormConfig"
      class="basic-data-add-form"
    />
    <div class="buttons-wrapper" style="text-align: center">
      <el-button @click="$emit('update:visible', false)">
        {{ $t('lang.gles.common.cancel') }}
      </el-button>
      <el-button type="primary" @click="handleSubmit">
        {{ $t('lang.gles.common.confirm') }}
      </el-button>
    </div>
  </m-dialog>
</template>
<script>
import { mapState } from 'vuex'
import { getAddWorkTypeFormData } from '../data'
import addMixins from '@/mixins/addMixins'
import myMixins from '../mixins'

export default {
  name: 'WorkTypeDialog',
  mixins: [addMixins, myMixins],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'add'
    },
    rowDetail: {
      type: Object,
      default: () => ({})
    },
    workTypes: {
      type: Array,
      default: () => ({})
    }
  },
  data() {
    return {
      currentWorkTypeList: [],
      formModel: {},
      // form表单相关的
      extendFormConfig: {
        isNeedBtn: false
      },
      currentExecuteModeList: []
    }
  },
  computed: {
    ...mapState('workflow', ['robotTypeList', 'robotWorkTypeList', 'executeModeList']),
    baseFormData() {
      return getAddWorkTypeFormData(this, { ...this.formModel })
    }
  },
  watch: {
    'formModel.robotType'() {
      this.setCurrentWorkTypeList(this.formModel.robotType, this.formModel.executeMode)
    },
    rowDetail: {
      handler() {
        this.formModel = this._.cloneDeep(this.rowDetail)
      },
      deep: true
    }
  },
  created() {
    this.initSelectList()
    if (this.mode !== 'add') {
      this.formModel = this._.cloneDeep(this.rowDetail)
      this.setExecuteModeList(this.formModel.robotType)
      this.setCurrentWorkTypeList(this.formModel.robotType, this.formModel.executeMode)
    }
  },
  methods: {
    initSelectList() {
      if (!this.robotTypeList?.length) {
        this.$store.dispatch('workflow/getRobotTypeList')
      }
      if (!this.robotWorkTypeList?.length) {
        this.$store.dispatch('workflow/getRobotWorkTypeList')
      }
      if (!this.executeModeList?.length) {
        this.$store.dispatch('workflow/getExecuteModeList')
      }
    },
    /**
     * 设置搬运模式默认值
     */
    setDefaultExecuteMode() {
      const robotType = Number(this.formModel.robotType)
      if ([1, 2].includes(robotType)) {
        this.formModel.executeMode = 0
      } else if ([3, 4].includes(robotType)) {
        this.formModel.executeMode = 1
      } else if ([5].includes(robotType)) {
        this.formModel.executeMode = 0
      }
    },
    /**
     * 机器人类型切换
     */
    handleRobotTypeChange(val, formItem, changeFormValue, formModel) {
      this.$set(this.formModel, formItem.name, val)
      // 设置当前可选执行模式
      this.setExecuteModeList(val)

      // 清空已选单据类型
      if (this.formModel.checkedWorkTypeList?.length) {
        this.formModel.checkedWorkTypeList = []
        this.$emit('update:row-detail', this._.cloneDeep(this.formModel))
      }
      this.setDefaultExecuteMode()
    },
    /**
     * 执行模式
     * 暂时没用，等执行模式有多重情况时，可用
     */
    handleExecuteModeChange(val, formItem, changeFormValue, formModel) {
      this.$set(this.formModel, formItem.name, val)
      // 设置当前可选单据类型
      this.setCurrentWorkTypeList(this.formModel.robotType, val)

      // 清空已选单据类型
      if (this.formModel.checkedWorkTypeList?.length) {
        this.formModel.checkedWorkTypeList = []
        this.$emit('update:row-detail', this._.cloneDeep(this.formModel))
      }
    },
    /**
     * 双举升系列 标准
     * 悬臂系列 标准
     * 举升系列 标准 搬运
     * 滚筒系列 搬运
     */
    setExecuteModeList(robotType) {
      if ([1, 2].includes(robotType)) {
        this.currentExecuteModeList = this.executeModeList.filter(t => t.value === 0)
      } else if ([3, 4].includes(robotType)) {
        this.currentExecuteModeList = this.executeModeList.filter(t => t.value === 1)
      } else if ([5].includes(robotType)) {
        this.currentExecuteModeList = this._.cloneDeep(this.executeModeList)
      }
    },
    /**
     * 根据机器人类型设置当前单据类型数据列表
     */
    setCurrentWorkTypeList(robotType, executeMode) {
      let list = []
      switch (robotType) {
        case 1:
        case 5:
          list = list = this.robotWorkTypeList.filter(
            (item) => ![301].includes(item.value)
          )
          break
        case 2:
          // 上空下满 上满下空
          list = this.robotWorkTypeList.filter(
            (item) => ![1, 2, 301].includes(item.value)
          )
          break
        case 3:
        case 4:
          // 举升系列 滚筒 搬运模式
          // 上满、上空、下满、下空、上满下空、上空下满、退料
          if (executeMode === 1) {
            list = list = this.robotWorkTypeList.filter(
              (item) => ![301, 302].includes(item.value)
            )
          } else {
            list = []
          }
          break
        default:
          break
      }
      // 禁用已选的单据类型
      const workTypes = this.workTypes?.map((t) => t.workType)
      list.forEach((item) => {
        item.disabled =
          workTypes.includes(item.value) &&
          this.rowDetail.workType !== item.value
      })
      this.currentWorkTypeList = list
    },
    /**
     * 选中作业类型
     */
    handleCheckWorkType(val, formItem) {
      this.formModel.checkedWorkTypeList = val
    },
    handleReset() {
      this.$refs.myForm.resetForm()
      this.$emit('update:row-detail', {})
      this.formModel = {}
      this.$emit('update:visible', false)
    },
    /**
     * 保存
     */
    handleSubmit() {
      this.$refs.myForm.getValidateFormModel().then((model) => {
        const list = this.formModel?.checkedWorkTypeList?.map((item, i) => {
          const nextId = this.workTypes?.length + 1
          return {
            index: i === 0 ? this.rowDetail.index ?? nextId : nextId,
            robotType: model.robotType,
            executeMode: model.executeMode,
            robotTypeName: this.getRobotTypeName(model),
            workType: item,
            workTypeName: this.getWorkTypeName({ workType: item }),
            enableStatus: 1,
            carryPosition: this.rowDetail.carryPosition
          }
        })
        this.formModel.checkedWorkTypeList = []
        this.handleReset()
        this.$emit('onSubmit', [...list])
      })
    }
  }
}
</script>
<style lang="scss">
.el-dialog__body {
  .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
  }
  .Mform .el-form-item label {
    width: auto;
  }
}
</style>
