<template>
  <div class="basic-data-add">
    <header-path :paths="headerPaths" />
    <work-position
      ref="workPositonForm"
      :row-detail.sync="formModel"
      :table-data.sync="totalWorkPositions"
      :mode="mode"
      @update:table-data="totalWorkPositions = $event"
    />
    <work-type
      ref="workTypeForm"
      :work-position-config-id="rowDetail.id"
      :table-data.sync="workTypes"
      :active-tab.sync="workTypeActiveTab"
      :notice-list.sync="formModel.informConfigs"
      style="margin-top: 10px"
      @update:table-data="workTypes = $event"
    />
    <div class="buttons-wrapper" style="text-align: center; margin-top: 20px">
      <el-button @click="onCancel">
        {{ $t('lang.gles.common.cancel') }}
      </el-button>
      <el-button v-debounce="handleSubmit" :loading="vloading" type="primary">
        {{ $t('lang.gles.common.confirm') }}
      </el-button>
    </div>
  </div>
</template>
<script>
import HeaderPath from '@/components/headerPath/index.vue'
import { getEditBaseFormData, getWorkPositionTableItem } from '../data'
import addMixins from '@/mixins/addMixins'
import { mapState } from 'vuex'
import WorkPosition from './workPosition.vue'
import WorkType from './workType.vue'
import myMixins from '../mixins'

export default {
  name: 'WorkPositionConfigAdd',
  components: {
    HeaderPath,
    WorkPosition,
    WorkType
  },
  mixins: [addMixins, myMixins],
  props: {
    mode: {
      type: String,
      default: 'add'
    },
    rowDetail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      pageData: {
        currentPage: 1,
        pageSize: 5,
        recordCount: 0
      },
      // form表单相关的
      extendFormConfig: {
        isNeedBtn: false
      },
      formModel: {
        robotType: null,
        selectedWorkPositionList: []
      },
      noticeFormModel: {
        // 默认关闭
        noticeStatus: 1,
        noticeTiming: 2,
        noticeThirdInterface: ''
      },
      // 工位位置
      workPositionList: [],
      // 所有工位列表
      totalWorkPositions: [],
      // 前端分页：当前页工位配置列表
      workPositions: [],
      // 工位多选列表
      workPositionMultipleSelectionList: [],
      // 作业类型列表
      workTypes: [],
      workTypeActiveTab: 'first'
    }
  },
  computed: {
    ...mapState('workflow', [
      'robotTypeList',
      'robotWorkTypeList',
      'workPositionTypeList'
    ]),
    headerPaths() {
      return this.mode === 'edit'
        ? [this.$t('lang.gles.workflow.workPositionEdit')]
        : this.mode === 'batchAdd'
        ? [this.$t('lang.gles.workflow.workPositionBatchAdd')]
        : [this.$t('lang.gles.workflow.workPositionAdd')]
    },
    baseFormData() {
      const row = this.rowDetail || {}
      return getEditBaseFormData(this, { ...row })
    },
    /**
     * 工位配置表格表头
     */
    workPositionTableItem() {
      return getWorkPositionTableItem(this)
    }
  },
  created() {
    this.initSelectList()
    if (this.rowDetail?.id) {
      this.getDetail({ id: this.rowDetail.id })
    }
  },
  methods: {
    initSelectList() {
      if (!this.robotWorkTypeList?.length) {
        this.$store.dispatch('workflow/getRobotWorkTypeList')
      }
      if (!this.workPositionTypeList?.length) {
        this.$store.dispatch('workflow/getWorkPositionTypeList')
      }
      if (!this.robotTypeList?.length) {
        this.$store.dispatch('workflow/getRobotTypeList')
      }
    },
    /**
     * 获取工位详情
     */
    async getDetail(params) {
      const { data, code } = await this.$httpService(
        this.$apiStore.workflow('queryPositionConfigDetail'),
        params
      )
      if (code) return

      // 单据类型
      const { items: workTypes } = data
      workTypes.forEach((item, i) => {
        item.index = i
        item.robotTypeName = this.getRobotTypeName(item)
        item.workTypeName = this.getWorkTypeName(item)
        item.isSelection = !!item.enableStatus
      })
      this.workTypes = workTypes

      // 工位工位
      this.totalWorkPositions = [
        {
          id: data.id,
          areaId: data.areaId,
          productionLineId: data.productionLineId,
          deviceId: data.deviceId,
          workPositionId: data.workPositionId,
          workPositionName: data.workPositionName,
          workPositionType: data.workPositionType
        }
      ]

      this.formModel = {
        ...data,
        selectedWorkPositionList: this.getEchoSelectedWorkPositionList(data)
      }
    },
    /**
     * 回显选中的工位位置
     */
    getEchoSelectedWorkPositionList(data) {
      // 设备货位
      if (data.workPositionType === 1) {
        return [
          `${data.areaId},${data.productionLineId},${data.deviceId}`,
          `${data.workPositionId},${data.workPositionCode}`
        ]
      } else if (data.workPositionType === 2) {
        return [
          `${data.areaId}`,
          `${data.workPositionId},${data.workPositionCode}`
        ]
      } else {
        return []
      }
    },
    /**
     * 保存
     */
    handleSubmit() {
      // 先校验
      this.$refs.workPositonForm?.$refs?.myForm
        ?.getValidateFormModel()
        .then((model) => {
          if (this.mode === 'batchAdd') {
            this.$refs.workPositonForm?.$refs?.positionTableForm
              .validateTableForm()
              .then((data) => {
                this.submitForm(this.formModel)
              })
          } else {
            this.submitForm(this.formModel)
          }
        })
    },
    /**
     * 表单提交
     */
    async submitForm() {
      if (!this.workTypes?.length) {
        this.$message.error(
          this.$t('lang.gles.workflow.workPosition.checkMsg0')
        )
        return
      }
      if (!this.formModel?.informConfigs?.length) {
        this.$message.error(this.$t('保存失败， 作业通知未设置'))
        this.workTypeActiveTab = 'second'
        return
      }
      let params = {
        workPositions: this.totalWorkPositions,
        workTypes: this.workTypes,
        id: this.rowDetail.id,
        informConfigs: this.formModel.informConfigs
      }
      // 编辑时
      if (this.mode === 'edit') {
        delete params.workPositions
        params = {
          ...params,
          ...this.totalWorkPositions[0]
        }
      }
      this.mode !== 'edit'
        ? await this.insert(params)
        : await this.update(params)
    },
    /**
     * 添加
     */
    async insert(params) {
      this.vloading = true
      setTimeout(() => {
        this.vloading = false
      }, 500)
      const { code } = await this.$httpService(
        this.$apiStore.workflow('addWorkPositionConfig'),
        params
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.addSuccessfully'))
      this.onCancel()
    },
    /**
     * 更新
     */
    async update(params) {
      this.vloading = true
      setTimeout(() => {
        this.vloading = false
      }, 500)
      const { code } = await this.$httpService(
        this.$apiStore.workflow('updateWorkPositionConfig'),
        params
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.updateSuccessfully'))
      this.onCancel()
    },
    /**
     * 取消
     */
    onCancel() {
      this.$emit('updateCom', {
        currentCom: 'WorkPositionConfigList'
      })
    }
  }
}
</script>
