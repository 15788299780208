<template>
  <el-card class="add-card">
    <m-form
      ref="myForm"
      :form-data="baseFormData"
      label-position="right"
      :label-width="120"
      :extend-config="extendFormConfig"
      class="basic-data-add-form"
    />
    <section v-if="mode === 'batchAdd'" class="work-position-wrapper">
      <div class="title-wrapper" style="margin-bottom: 4px">
        <span style="margin-right: 10px; font-size: 12px">{{
          $t('lang.gles.workflow.selectedWorkPosition')
        }}</span>
        <el-button
          type="text"
          icon="el-icon-delete-solid"
          class="danger"
          :disabled="!workPositionMultipleSelectionList.length"
          @click="handleBatchDeleteWorkPosition"
        >
          {{ $t('lang.gles.common.batchDelete') }}
        </el-button>
      </div>
      <table-form
        ref="positionTableForm"
        :table-item="workPositionTableItem"
        :table-data.sync="workPositions"
        :page-data="pageData"
        :extend-config="tableExtendConfig"
        border
        @delItem="handleDeleteWorkPosition"
        @pageChange="pageChange"
        @selection-change="handleSelectionChange"
        @update:table-data="updateWorkPositions"
      />
    </section>
  </el-card>
</template>
<script>
import { mapState } from 'vuex'
import TableForm from '@/components/tableForm/index.vue'
import { getEditBaseFormData, getWorkPositionTableItem } from '../data'
import addMixins from '@/mixins/addMixins'
export default {
  name: 'WorkPosition',
  components: {
    TableForm
  },
  mixins: [addMixins],
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    mode: {
      type: String,
      default: 'add'
    },
    rowDetail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      tableExtendConfig: {
        checkBox: true,
        operateWidth: '60px',
        operate: [
          {
            event: 'delItem',
            confirm: true,
            confirmMessage: this.$t('lang.gles.common.deleteConfirmMsg0'),
            label: this.$t('lang.gles.common.delete'),
            customClass: 'danger'
          }
        ]
      },
      formModel: {
        selectedWorkPositionList: []
      },
      // form表单相关的
      extendFormConfig: {
        isNeedBtn: false
      },
      // 工位位置
      workPositionList: [],
      // 所有工位列表
      totalWorkPositions: [],
      // 前端分页：当前页工位配置列表
      workPositions: [],
      // 工位多选列表
      workPositionMultipleSelectionList: []
    }
  },
  computed: {
    ...mapState('workflow', [
      'robotTypeList',
      'robotWorkTypeList',
      'workPositionTypeList'
    ]),
    baseFormData() {
      return getEditBaseFormData(this, { ...this.formModel })
    },
    /**
     * 工位配置表格表头
     */
    workPositionTableItem() {
      return getWorkPositionTableItem(this)
    }
  },
  watch: {
    tableData() {
      this.initPageData()
    },
    rowDetail() {
      this.formModel = this._.cloneDeep(this.rowDetail)
      if (this.rowDetail.workPositionType) {
        this.getWorkPositionByType({ type: this.rowDetail.workPositionType })
      }
    }
  },
  created() {
    this.initSelectList()
    this.initPageData()
  },
  methods: {
    initSelectList() {
      if (!this.robotWorkTypeList?.length) {
        this.$store.dispatch('workflow/getRobotWorkTypeList')
      }
      if (!this.workPositionTypeList?.length) {
        this.$store.dispatch('workflow/getWorkPositionTypeList')
      }
      if (!this.robotTypeList?.length) {
        this.$store.dispatch('workflow/getRobotTypeList')
      }
    },
    initPageData() {
      this.totalWorkPositions = this._.cloneDeep(this.tableData)
      this.pageData = {
        ...this.pageData,
        recordCount: this.totalWorkPositions?.length
      }
      this.workPositions = this.getCurrentPageData(
        this.totalWorkPositions,
        this.pageData
      )
    },
    /**
     * 前端获取当前分页数据
     */
    getCurrentPageData(list = [], pageData = {}) {
      const { currentPage, pageSize } = pageData
      return (
        list.filter((item, i) => {
          const startIdx = (currentPage - 1) * pageSize
          const endIdx = currentPage * pageSize
          const idx = i + 1
          return idx > startIdx && idx <= endIdx
        }) ?? []
      )
    },
    /**
     * 工位配置分页切换
     */
    pageChange(pageData) {
      this.pageData = { ...this.pageData, ...pageData }
      this.workPositions = this.getCurrentPageData(
        this.totalWorkPositions,
        this.pageData
      )
    },
    /**
     * 分页编辑时，更新tableData
     * @param {*} tableData
     */
    updateWorkPositions(tableData) {
      const tempTotalData = this._.cloneDeep(this.totalWorkPositions)
      tempTotalData.forEach((t, i) => {
        tableData.forEach((w) => {
          if (t.index === w.index) {
            tempTotalData[i] = w
          }
        })
      })
      this.totalWorkPositions = tempTotalData
      this.updateTableData()
    },
    /**
     * 工位类型： 设备货位/生产库位
     */
    handleWorkPositionType(val, formItem, changeFormValue, formModel) {
      this.$set(this.formModel, formItem.name, val)
      // 清空列表
      this.totalWorkPositions = []
      this.selectedWorkPositionList = []
      this.updateTableData()
      this.getWorkPositionByType({ type: val })
    },
    /**
     * 根据工位类型获取工位位置
     */
    async getWorkPositionByType(params) {
      const { data, code } = await this.$httpService(
        this.$apiStore.workflow('getWorkPositionByType'),
        params
      )
      if (code) return
      const list = data ?? []
      const workPositionList = list.map((item) => {
        // 设备货位
        let value = ''
        let label = ''
        let children = []
        if (params.type === 1) {
          value = `${item.areaId},${item.productionLineId},${item.deviceId}`
          label = item.deviceName
        } else if (params.type === 2) {
          value = `${item.areaId}`
          label = item.areaName
        }
        children = (item?.workPositions || []).map((t) => {
          return {
            value: `${t.workPositionId},${t.workPositionCode}`,
            label: t.workPositionCode,
            disabled: !!t.exist
          }
        })
        return {
          label,
          value,
          children
        }
      })
      this.workPositionList = workPositionList
    },
    /**
     * 工位位置
     */
    handleWorkPosition(val, formItem, changeFormValue, formModel) {
      if (!val) return
      this.formModel.selectedWorkPositionList = val
      if (this.mode === 'batchAdd') {
        const list = val.map((item, i) => {
          const model = this.parseWorkPosition(item)
          return {
            ...model,
            workPositionName:
              this.formModel.workPositionName ?? model.workPositionCode,
            workPositionType: this.formModel.workPositionType,
            index: i
          }
        })
        this.totalWorkPositions = list
        this.pageData = {
          ...this.pageData,
          recordCount: this.totalWorkPositions?.length
        }
      } else {
        const model = this.parseWorkPosition(val)
        this.totalWorkPositions = [
          {
            ...model,
            workPositionName:
              this.formModel?.workPositionName ?? model.workPositionCode,
            workPositionType: this.formModel?.workPositionType,
            index: 1
          }
        ]
        this.formModel.workPositionName =
        this.formModel?.workPositionName ?? model.workPositionCode
      }
      this.$emit('update:row-detail', {
        ...this.rowDetail,
        ...this.formModel
      })
      this.workPositions = this.getCurrentPageData(
        this.totalWorkPositions,
        this.pageData
      )
      this.updateTableData()
    },
    /**
     * 解析工位位置
     * 区域、产线、设备、工位id、工位编码
     */
    parseWorkPosition(val = []) {
      let areaId, productionLineId, deviceId
      if (this.formModel?.workPositionType === 1) {
        areaId = parseInt(val[0]?.split(',')[0])
        productionLineId = parseInt(val[0]?.split(',')[1])
        deviceId = parseInt(val[0]?.split(',')[2])
      } else if (this.formModel?.workPositionType === 2) {
        areaId = parseInt(val[0])
      }
      return {
        areaId,
        productionLineId,
        deviceId,
        workPositionId: val[1].split(',')[0],
        workPositionCode: val[1].split(',')[1]
      }
    },
    /**
     * 工位名称change事件
     * @param {*} val
     * @param {*} formItem
     */
    handleWorkPositionName(val, formItem) {
      this.$set(this.formModel, formItem.name, val)
      this.totalWorkPositions.forEach((item) => {
        item.workPositionName = val
      })
      this.updateTableData()
    },
    /**
     * 更新工位列表
     */
    updateTableData() {
      this.$emit('update:table-data', this._.cloneDeep(this.totalWorkPositions))
    },
    /**
     * 工位多选
     */
    handleSelectionChange(val) {
      this.workPositionMultipleSelectionList = val
    },
    /**
     * 单条删除工位
     */
    handleDeleteWorkPosition({ row }) {
      // 更新当前选中工位
      this.formModel.selectedWorkPositionList =
        this.formModel.selectedWorkPositionList?.filter(
          (item) =>
            parseInt(item[1].split(',')[0]) !== parseInt(row.workPositionId)
        )

      // 更新工位位置表格数据
      this.totalWorkPositions = this.totalWorkPositions.filter(
        (item) => row.workPositionId !== item.workPositionId
      )

      // 更新分页信息
      this.pageData.recordCount = this.totalWorkPositions?.length
      if (this.workPositions?.length === 1) {
        this.pageData.currentPage = 1
      }
      this.workPositions = this.getCurrentPageData(
        this.totalWorkPositions,
        this.pageData
      )

      // 同步父组件
      this.updateTableData()
    },
    /**
     * 批量删除
     */
    handleBatchDeleteWorkPosition() {
      // 所有删除工位位置id
      const ids = this.workPositionMultipleSelectionList.map((item) =>
        parseInt(item.workPositionId)
      )

      // 更新当前选中工位位置
      this.formModel.selectedWorkPositionList =
        this.formModel.selectedWorkPositionList?.filter(
          (item) => !ids.includes(parseInt(item[1].split(',')[0]))
        )

      // 更新工位位置表格数据
      this.totalWorkPositions = this.totalWorkPositions.filter(
        (item) => !ids.includes(parseInt(item.workPositionId))
      )

      // 更新分页信息
      this.pageData.recordCount = this.totalWorkPositions?.length
      if (ids?.length === this.workPositions?.length) {
        this.pageData.currentPage = 1
      }
      this.workPositions = this.getCurrentPageData(
        this.totalWorkPositions,
        this.pageData
      )

      // 同步父组件
      this.updateTableData()
    }
  }
}
</script>
