<template>
  <el-card class="add-card">
    <el-tabs
      :value="activeTab"
      @tab-click="handleClickTabs"
      @input="$emit('update:active-tab', $event)"
    >
      <el-tab-pane :label="$t('lang.gles.workflow.workType')" name="first">
        <div v-if="mode !== 'detail'" class="title-wrapper">
          <el-button
            type="text"
            icon="el-icon-circle-plus"
            @click="handleAddWorkType"
          >
            {{ $t('lang.gles.workflow.addType') }}
          </el-button>
          <el-button
            type="text"
            icon="el-icon-delete-solid"
            class="danger"
            :disabled="!workTypeMultipleSelectionList.length"
            @click="handleBatchDeleteWorkType"
          >
            {{ $t('lang.gles.common.batchDelete') }}
          </el-button>
        </div>
        <table-form
          :table-item="workTypeTableItem"
          :table-data.sync="innerTableData"
          :extend-config="tableExtendConfig"
          @delItem="handleDeleteWorkType"
          @editItem="handleEditWorkType"
          @selection-change="handleWorkTypeSelectionChange"
          @update:table-data="updateTableData"
        >
          <template #enableStatus="{ row, $index }">
            <el-switch
              :value="row.enableStatus"
              class="switch"
              :active-text="$t('lang.gles.common.enable')"
              :inactive-text="$t('lang.gles.common.disable')"
              :active-value="0"
              :inactive-value="1"
              :disabled="mode === 'detail'"
              @click.native="handleEnableStatus(row, $index)"
            />
          </template>
        </table-form>
      </el-tab-pane>
      <el-tab-pane :label="$t('lang.gles.workflow.workNotice')" name="second">
        <work-notice
          :notice-list="noticeList"
          :mode="mode"
          @update:noticeList="$emit('update:noticeList', $event)"
        />
      </el-tab-pane>
    </el-tabs>
    <work-type-dialog
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      :mode="workTypeMode"
      :row-detail.sync="workTypeRow"
      :work-types="innerTableData"
      @onSubmit="handleAddWorkTypeSubmit"
    />
  </el-card>
</template>
<script>
import TableForm from '@/components/tableForm/index'
import WorkNotice from './workNotice.vue'
import WorkTypeDialog from './workTypeDialog'
import { getWorkNoticeFormData, getWorkTypeTableItem } from '../data'
import { mapState } from 'vuex'
import commonMixins from '@/mixins/commonMixins'

export default {
  name: 'WorkType',
  components: {
    WorkTypeDialog,
    TableForm,
    WorkNotice
  },
  mixins: [commonMixins],
  props: {
    mode: {
      type: String,
      default: 'add'
    },
    /**
     * 作业类型列表
     */
    tableData: {
      type: Array,
      default: () => []
    },
    activeTab: {
      type: String,
      default: 'first'
    },
    workPositionConfigId: {
      type: Number,
      default: null
    },
    noticeList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dialogVisible: false,
      workTypeMode: 'add',
      workTypeRow: {},
      rowDetail: {},
      // 作业类型多选列表
      workTypeMultipleSelectionList: [],
      tableExtendConfig: {
        checkBox: true,
        operateWidth: '120px',
        operate: [
          {
            event: 'editItem',
            label: this.$t('lang.gles.common.edit')
          },
          {
            event: 'delItem',
            confirm: true,
            confirmMessage: this.$t('lang.gles.common.deleteConfirmMsg0'),
            label: this.$t('lang.gles.common.delete'),
            customClass: 'danger'
          }
        ]
      },
      innerTableData: [],
      // 双举升系列机器人作业模板
      doubleLiftRobotWorkTemplateList: [],
      // 悬臂系列机器人作业模板
      cantileverRobotWorkTemplateList: [],
      // 潜伏系列机器人作业模板
      lurkRobotWorkTemplateList: [],
      rollerRobotWorkTemplateList: [],
      // 晶棒抓取机器人作业模板
      catchrobotWorkTemplateList: [],
      noticeExtendConfig: {
        isNeedBtn: false
      },
      editRowIndex: null,
      initFilterData: []
    }
  },
  computed: {
    ...mapState('workflow', [
      'robotWorkTypeList',
      'robotTypeList',
      'noticeTimingList',
      'executeModeList',
      'carryPositionList'
    ]),
    /**
     * 作业类型表格表头
     */
    workTypeTableItem() {
      return getWorkTypeTableItem(this)
    },
    noticeFormData() {
      return getWorkNoticeFormData(this, { ...this.noticeFormModel })
    }
  },
  watch: {
    tableData() {
      this.innerTableData = this._.cloneDeep(this.tableData)
    },
    noticeFormModel() {
      this.$refs.myForm?.changeFormValue(this.noticeFormModel)
    }
  },
  created() {
    this.innerTableData = this._.cloneDeep(this.tableData)
    this.initSelectList()
    if (this.mode === 'detail') {
      this.tableExtendConfig.operate = []
    }
  },
  methods: {
    initSelectList() {
      if (!this.workTypeList?.length) {
        this.$store.dispatch('workflow/getRobotWorkTypeList')
      }
      if (!this.robotTypeList?.length) {
        this.$store.dispatch('workflow/getRobotTypeList')
      }
      if (!this.executeModeList?.length) {
        this.$store.dispatch('workflow/getExecuteModeList')
      }
      if (!this.carryPositionList?.length) {
        this.$store.dispatch('workflow/getCarryPositionList')
      }
      this.getWorkTemplateByRobotType(
        { type: 1 },
        'doubleLiftRobotWorkTemplateList'
      )
      this.getWorkTemplateByRobotType(
        { type: 2 },
        'cantileverRobotWorkTemplateList'
      )
      this.getWorkTemplateByRobotType({ type: 3 }, 'lurkRobotWorkTemplateList')
      this.getWorkTemplateByRobotType(
        { type: 4 },
        'rollerRobotWorkTemplateList'
      )
      this.getWorkTemplateByRobotType(
        { type: 5 },
        'catchrobotWorkTemplateList'
      )
    },
    /**
     * 根据机器人类型获取已启用作业模板
     */
    async getWorkTemplateByRobotType(params, key) {
      const { data = [], code } = await this.$httpService(
        this.$apiStore.workflow('getWorkTemplateByRobotType'),
        params
      )
      if (code) return
      const list = data || []
      this[key] = list?.map((t) => {
        return {
          label: t.templateName,
          value: t.id,
          executeMode: t.executeMode
        }
      })
    },
    /**
     * tab 切换
     */
    handleClickTabs(tab, event) {
      console.log(tab)
    },
    /**
     * 添加作业类型
     */
    handleAddWorkType() {
      this.dialogVisible = true
      this.workTypeMode = 'add'
      this.workTypeRow = {
        checkedWorkTypeList: []
      }
    },
    updateTableData(data) {
      this.$emit('update:table-data', this._.cloneDeep(this.innerTableData))
    },
    /**
     * 根据机器人类型获取已启用的作业模板
     */
    getWorkTemplateList(row) {
      let list = []
      if (row.robotType === 1) {
        list = this.doubleLiftRobotWorkTemplateList
      } else if (row.robotType === 2) {
        list = this.cantileverRobotWorkTemplateList
      } else if (row.robotType === 3) {
        list = this.lurkRobotWorkTemplateList
      } else if (row.robotType === 4) {
        list = this.rollerRobotWorkTemplateList
      } else if(row.robotType === 5){
        list = this.catchrobotWorkTemplateList
      } else {
        list = []
      }
      return list.filter((t) => t.executeMode === row.executeMode)
    },
    /**
     * 作业类型多选
     */
    handleWorkTypeSelectionChange(val) {
      this.workTypeMultipleSelectionList = val
    },
    /**
     * 选择作业模板
     */
    async handleWorkTemplateChange(val, formItem, $index) {
      if (!val) {
        this.innerTableData[$index].carryPosition = null
        this.innerTableData[$index].enableStatus = 1
        return
      }
      if (val) {
        await this.getCarryPosition({ id: val }, $index)
      }
      // this.$emit('update:tableData', this._.cloneDeep(this.innerTableData))
    },
    /**
     * 获取搬运库位
     */
    async getCarryPosition(params, $index) {
      const { data, code } = await this.$httpService(
        this.$apiStore.workflow('getWorkflowTemplate'),
        params
      )
      if (code !== 0) {
        return
      }
      this.innerTableData[$index].carryPosition = data.carryPosition
    },
    /**
     * 单据类型 启用/禁用
     */
    handleEnableStatus(row, index) {
      if (this.mode === 'detail') return
      if (!row.workTemplateId && row.enableStatus === 1) {
        this.$message.error(
          this.$t('lang.gles.workflow.workPosition.checkMsg2')
        )
        return
      }
      row.enableStatus = row.enableStatus ? 0 : 1
      if (row.enableStatus === 0) {
        row.isSelection = false
      }
      this.innerTableData[index] = row
      row.isSelection = !!row.enableStatus
      this.updateTableData()
    },
    /**
     * 编辑单据类型
     */
    handleEditWorkType({ row, $index }) {
      if (row.enableStatus === 0) {
        this.$message.error(
          this.$t('lang.gles.workflow.workPosition.checkMsg3')
        )
        return
      }
      this.editRowIndex = row.index
      this.dialogVisible = true
      this.workTypeMode = 'edit'
      this.workTypeRow = row ?? {}
      this.workTypeRow.checkedWorkTypeList = [row.workType]
    },
    /**
     * 单条删除作业类型
     */
    handleDeleteWorkType({ row }) {
      if (row.enableStatus === 0) {
        this.$message.error(
          this.$t('lang.gles.workflow.workPosition.checkMsg4')
        )
        return
      }
      const tableData = this.innerTableData.filter((item, i) => row.index !== item.index)
      this.$emit('update:table-data', tableData)
    },
    /**
     * 批量删除
     */
    handleBatchDeleteWorkType() {
      // 二次确认
      const confirmMsg = this.$t('lang.gles.common.deleteConfirmMsg0')
      this.$confirm(confirmMsg, '', {
        confirmButtonText: this.$t('lang.gles.common.confirm'),
        cancelButtonText: this.$t('lang.gles.common.cancel'),
        type: 'warning'
      }).then(() => {
        const ids = this.workTypeMultipleSelectionList.map((item) => item.index)
        const tableData = this.innerTableData.filter(
          (item) => !ids.includes(item.index)
        )
        this.$emit('update:table-data', tableData)
      })
    },
    handleAddWorkTypeSubmit(data) {
      if (this.workTypeMode === 'edit') {
        const oldIdx = this.innerTableData.findIndex(
          (t) => t.index === this.editRowIndex
        )
        const newIdx = data.findIndex((t) => t.index === this.editRowIndex)
        if (!isNaN(oldIdx) && !isNaN(newIdx)) {
          this.innerTableData[oldIdx] = data[newIdx]
          data.splice(newIdx, 1)
        }
      }
      const newTableData = [...this.innerTableData, ...data]
      newTableData.forEach((item, i) => (item.index = i))
      this.innerTableData = newTableData
      this.$emit('update:table-data', [...this.innerTableData])
      // 作业通知表单
    },
    handleCancel() {}
  }
}
</script>
