import mapVerify from '@/utils/formValidate'
import myTransform from '@/libs_sz/utils/transform'

const getFormatter = (data, value) => {
  const getValue = myTransform.arrToObject(data, 'value', 'label')[value] || value
  return getValue === 'null' || getValue === 0 ? '' : getValue
}
// 获取更多查询字段列表
export const getMoreQueryFormData = (that, formModel) => {
  const moreQueryData = [
    // 工位位置
    {
      name: 'workPositionCode',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.workPosition')
    },
    // 工位名称
    {
      name: 'workPositionName',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.workPositionName')
    },
    // 工位类型
    {
      name: 'workPositionType',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.workPositionType'),
      data: that.workPositionTypeList
    },
    // 启用作业类型
    {
      name: 'workType',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.workType'),
      filterable: true,
      data: that.robotWorkTypeList
    },
    {
      name: 'areaId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingArea'),
      filterable: true,
      data: that.areaList,
      mchange: that.handleAreaChange
    },
    {
      name: 'productionLineId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingProductionLine'),
      filterable: true,
      data: that.productionLineList,
      mchange: that.handleProductionLineChange
    },
    {
      name: 'deviceId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingDevice'),
      filterable: true,
      data: that.deviceList
    }
  ]
  return moreQueryData
}
// 列表列表
export const getSearchTableItem = (that) => {
  const searchTableItem = [
    // 工位位置
    {
      prop: 'workPositionCode',
      label: `${that.$t('lang.gles.workflow.workPosition')}`,
      isShow: true,
      slotName: 'clickSlot'
    },
    // 工位类型
    {
      prop: 'workPositionType',
      label: that.$t('lang.gles.workflow.workPositionType'),
      isShow: true,
      formatter(row, column) {
        return that.getDictLabel(that.workPositionTypeList, row[column])
      }
    },
    // 工位名称
    {
      prop: 'workPositionName',
      label: `${that.$t('lang.gles.workflow.workPositionName')}`,
      isShow: true
    },
    // 启用作业类型
    {
      prop: 'enableWorkTypes',
      label: `${that.$t('lang.gles.workflow.enableWorkType')}`,
      isShow: true
    },
    // 所属区域
    {
      prop: 'areaName',
      label: `${that.$t('lang.gles.base.belongingArea')}`,
      isShow: true
    },
    // 所属产线
    {
      prop: 'productionLineName',
      label: `${that.$t('lang.gles.base.belongingProductionLine')}`,
      isShow: true
    },
    // 所属设备
    {
      prop: 'deviceName',
      label: `${that.$t('lang.gles.base.belongingDevice')}`,
      isShow: true
    },
    // 创建人
    {
      prop: 'createUser',
      label: `${that.$t('lang.gles.common.createUser')}`,
      isShow: true
    },
    // 创建时间
    {
      prop: 'createTime',
      label: `${that.$t('lang.gles.common.createTime')}`,
      isShow: true,
      minWidth: 180
    },
    // 编辑人
    {
      prop: 'updateUser',
      label: `${that.$t('lang.gles.common.updateUser')}`,
      isShow: true
    },
    // 编辑时间
    {
      prop: 'updateTime',
      label: `${that.$t('lang.gles.common.updateTime')}`,
      isShow: true,
      minWidth: 180
    }
  ]
  searchTableItem.forEach((item) => (item.showOverflowTooltip = true))
  return searchTableItem
}

// 工位配置表单基础信息
export const getEditBaseFormData = (that, row) => {
  const formData = [
    // 工位类型
    {
      name: 'workPositionType',
      value: row.workPositionType || '',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.workPositionType'),
      rules: mapVerify(['required']),
      data: that.workPositionTypeList,
      mchange: that.handleWorkPositionType,
      isShow: true,
      disabled: that.mode !== 'add' && that.mode !== 'batchAdd'
    },
    // 工位位置
    {
      name: 'selectedWorkPositionList',
      value: row.selectedWorkPositionList || '',
      span: 12,
      component: 'elCascader',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.workPosition'),
      rules: mapVerify(['required']),
      options: that.workPositionList,
      props: {
        multiple: that.mode === 'batchAdd'
      },
      collapseTags: true,
      mchange: that.handleWorkPosition,
      isShow: true,
      disabled: that.mode === 'detail'
    },
    // 工位名称
    {
      name: 'workPositionName',
      value: row.workPositionName || '',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.workPositionName'),
      rules: mapVerify(['required', 'inputName']),
      isShow: that.mode !== 'batchAdd',
      mchange: that.handleWorkPositionName,
      disabled: that.mode === 'detail'
    }
  ]
  return formData.filter((item) => item.isShow)
}
export const getWorkPositionTableItem = (that) => {
  const tableItem = [
    {
      prop: 'workPositionCode',
      label: `${that.$t('lang.gles.workflow.workPosition')}`,
      isShow: true
    },
    {
      prop: 'workPositionName',
      component: 'elInput',
      label: `${that.$t('lang.gles.workflow.workPositionName')}`,
      isShow: true,
      rules: mapVerify(['required', 'inputName'])
    }
  ]
  return tableItem
}

export const getWorkTypeTableItem = (that) => {
  const searchTableItem = [
    // 作业类型
    {
      prop: 'workTypeName',
      label: `${that.$t('lang.gles.workflow.workType')}`,
      isShow: true
    },
    // 作业机器人类型
    {
      prop: 'robotTypeName',
      label: that.$t('lang.gles.workflow.workRobotType'),
      isShow: true
    },
    // 执行模式
    {
      prop: 'executeMode',
      label: that.$t('lang.gles.workflow.executeMode'),
      isShow: true,
      formatter(row, column) {
        return getFormatter(that.executeModeList, row[column])
      }
    },
    // 作业模板
    {
      prop: 'workTemplateId',
      label: `${that.$t('lang.gles.workflow.workTemplate')}`,
      isShow: true,
      component: 'elSelect',
      data: (row) => that.getWorkTemplateList(row),
      disabled: that.mode === 'detail',
      mchange: that.handleWorkTemplateChange
    },
    // 搬运库位
    {
      prop: 'carryPosition',
      label: `${that.$t('lang.gles.workflow.carryPosition')}`,
      isShow: true,
      formatter(row, column) {
        return getFormatter(that.carryPositionList, row[column])
      }
    },
    // 启用状态
    {
      prop: 'enableStatus',
      label: `${that.$t('lang.gles.base.usableFlag')}`,
      isShow: true,
      class: 'switch',
      disabled: that.mode === 'detail',
      slotName: 'enableStatus'
    }
  ]
  return searchTableItem
}

export const getAddWorkTypeFormData = (that, row) => {
  const formData = [
    // 作业机器人类型
    {
      name: 'robotType',
      value: row.robotType || '',
      span: 24,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: `${that.$t('lang.gles.workflow.workRobotType')}`,
      isShow: true,
      data: that.robotTypeList,
      mchange: that.handleRobotTypeChange,
      rules: mapVerify(['required'])
    },
    // 执行模式
    {
      name: 'executeMode',
      value: row.executeMode ?? that.setDefaultExecuteMode(),
      span: 24,
      component: 'elRadioGroup',
      adaptionw: true,
      showWordLimit: false,
      label: `${that.$t('lang.gles.workflow.executeMode')}`,
      condition(editForm) {
        return editForm.robotType
      },
      data: that.currentExecuteModeList,
      mchange: that.handleExecuteModeChange,
      rules: mapVerify(['required'])
    },
    // 选择作业类型
    {
      name: 'checkedWorkTypeList',
      value: row.checkedWorkTypeList || [],
      span: 24,
      component: 'elCheckboxGroup',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.selectWorkType'),
      data: that.currentWorkTypeList,
      rules: mapVerify(['requiredCheckBox']),
      condition(editForm) {
        return editForm.robotType
      },
      mchange: that.handleCheckWorkType
    }
  ]
  return formData
}

export const getWorkNoticeDialogFormData = (that, row) => {
  const formData = [
    // 选择单据类型
    {
      name: 'checkedWorkTypeList',
      value: row.checkedWorkTypeList || [],
      span: 24,
      component: 'elCheckboxGroup',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.selectWorkType'),
      data: that.currentWorkTypes,
      rules: mapVerify(['requiredCheckBox']),
      mchange: that.handleFormChange
    }
  ]
  return formData
}

export const getWorkNoticeFormData = (that, row) => {
  console.log('row', row)
  const formData = [
    // 单据类型
    {
      name: 'workTypes',
      value: row.workTypes ?? '',
      span: 24,
      adaptionw: true,
      showWordLimit: false,
      label: `${that.$t('lang.gles.workflow.workType')}`
    },
    // 第三方通知
    {
      name: 'noticeStatus',
      value: row.noticeStatus ?? 1,
      component: 'elSwitch',
      class: 'switch',
      span: 6,
      label: `${that.$t('lang.gles.workflow.hostNoticeFlag')}`,
      activeText: that.$t('lang.gles.common.enable'),
      inactiveText: that.$t('lang.gles.common.disable'),
      activeValue: 0,
      inactiveValue: 1,
      disabled: that.mode === 'detail' || !row.isEdit,
      mchange: that.handleFormChange
    },
    // 第三方获取搬运库位
    {
      name: 'factoryPositionStatus',
      value: row.factoryPositionStatus ?? 1,
      component: 'elSwitch',
      class: 'switch',
      span: 18,
      label: `${that.$t('lang.gles.workflow.factoryPositionStatus')}`,
      labelWidth: '160px',
      activeText: that.$t('lang.gles.common.enable'),
      inactiveText: that.$t('lang.gles.common.disable'),
      activeValue: 0,
      inactiveValue: 1,
      disabled: that.mode === 'detail' || !row.isEdit,
      mchange: that.handleFormChange
    },
    // 第三方通知接口
    {
      name: 'noticeThirdInterface',
      value: row.noticeThirdInterface ?? '',
      component: 'elInput',
      span: 24,
      adaptionw: true,
      showWordLimit: false,
      label: `${that.$t('lang.gles.workflow.noticeThirdInterface')}`,
      rules: mapVerify(['required', 'inputUrl', 'inputLen500']),
      condition(editForm) {
        return Number(editForm.noticeStatus) === 0
      },
      disabled: that.mode === 'detail' || !row.isEdit,
      mchange: that.handleFormChange
    },
    // 通知时机
    {
      name: 'noticeTimingList',
      value: row.noticeTimingList || [],
      span: 24,
      component: 'elCheckboxGroup',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.noticeTiming'),
      data: that.noticeTimingDictList,
      rules: mapVerify(['requiredCheckBox']),
      condition(editForm) {
        return Number(editForm.noticeStatus) === 0
      },
      disabled: that.mode === 'detail' || !row.isEdit,
      mchange: that.handleFormChange
    },
    // 通知信息
    {
      name: 'noticeInfoList',
      value: row.noticeInfoList || [],
      span: 24,
      component: 'elCheckboxGroup',
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.noticeInfo'),
      // eslint-disable-next-line no-return-assign
      data: that.noticeInfoDictList.map(t => {
        return {
          ...t,
          disabled: that.defaultNoticeInfo.includes(t.value)
        }
      }),
      rules: mapVerify(['requiredCheckBox']),
      condition(editForm) {
        return Number(editForm.noticeStatus) === 0
      },
      disabled: that.mode === 'detail' || !row.isEdit,
      mchange: that.handleFormChange
    },
    // 获取库位接口
    {
      name: 'thirdPositionUrl',
      value: row.thirdPositionUrl ?? '',
      component: 'elInput',
      span: 24,
      adaptionw: true,
      showWordLimit: false,
      label: `${that.$t('lang.gles.workflow.thirdPositionUrl')}`,
      rules: mapVerify(['required', 'inputUrl', 'inputLen500']),
      condition(editForm) {
        return Number(editForm.factoryPositionStatus) === 0
      },
      disabled: that.mode === 'detail' || !row.isEdit,
      mchange: that.handleFormChange
    }
  ]
  return formData
}
