<template>
  <m-dialog
    :visible="visible"
    :title="$t('lang.gles.workflow.addNotice')"
    :is-need-footer="false"
    @closed="handleReset"
    @input="handleReset"
  >
    <m-form
      ref="noticeForm"
      :form-data="baseFormData"
      label-position="top"
      :label-width="120"
      :extend-config="extendFormConfig"
      class="basic-data-add-form"
    />
    <div class="buttons-wrapper" style="text-align: center">
      <el-button @click="$emit('update:visible', false)">
        {{ $t('lang.gles.common.cancel') }}
      </el-button>
      <el-button type="primary" @click="handleSubmit">
        {{ $t('lang.gles.common.confirm') }}
      </el-button>
    </div>
  </m-dialog>
</template>
<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import { getWorkNoticeDialogFormData } from '../data'
import addMixins from '@/mixins/addMixins'
import myMixins from '../mixins'

export default {
  name: 'WorkNoticeDialog',
  mixins: [addMixins, myMixins],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'add'
    },
    rowDetail: {
      type: Object,
      default: () => ({})
    },
    /**
     * 已选择的单据类型
     */
    checkedWorkTypes: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      workTypeList: [],
      formModel: {},
      // form表单相关的
      extendFormConfig: {
        isNeedBtn: false
      }
    }
  },
  computed: {
    ...mapState('workflow', ['robotWorkTypeList']),
    currentWorkTypes() {
      // 禁用已选的单据类型
      const robotWorkTypeList = _.cloneDeep(this.robotWorkTypeList)
      robotWorkTypeList.forEach((item) => {
        item.disabled = this.checkedWorkTypes.includes(item.value)
      })
      return robotWorkTypeList
    },
    baseFormData() {
      return getWorkNoticeDialogFormData(this, { ...this.formModel })
    }
  },
  created() {
    this.initSelectList()
    this.formModel = this._.cloneDeep(this.rowDetail)
  },
  methods: {
    initSelectList() {
      if (!this.robotWorkTypeList?.length) {
        this.$store.dispatch('workflow/getRobotWorkTypeList')
      }
    },
    /**
     * 表单改变事件
     */
    handleFormChange(val, formItem) {
      this.formModel.checkedWorkTypeList = val
    },
    handleReset() {
      this.$refs.noticeForm.resetForm()
      this.$emit('update:row-detail', {})
      this.formModel = {}
      this.$emit('update:visible', false)
    },
    /**
     * 保存
     */
    handleSubmit() {
      this.$refs.noticeForm.getValidateFormModel().then((model) => {
        this.$emit('addNotice', [...this.formModel.checkedWorkTypeList])
        this.formModel.checkedWorkTypeList = []
        this.handleReset()
      })
    }
  }
}
</script>
<style lang="scss">
.el-dialog__body {
  .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
  }
  .Mform .el-form-item label {
    width: auto;
  }
}
</style>
