<template>
  <div class="basic-data-list">
    <query-view
      ref="myQueryView"
      name="codeOrName"
      :value.sync="baseFormModel.codeOrName"
      :placeholder="$t('lang.gles.common.pleaseInputCodeOrName')"
      :query-list="moreQueryData"
      @baseSearch="handleBaseSearch"
      @moreSearch="handleMoreSearch"
    />
    <el-card class="table-card">
      <div class="operator-wrapper">
        <el-button type="primary" icon="el-icon-plus" @click="updateCom('add')">
          {{ $t('lang.gles.common.addNew') }}
        </el-button>
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="updateCom('batchAdd')"
        >
          {{ $t('lang.gles.common.batchAdd') }}
        </el-button>
        <el-button
          type="danger"
          icon="el-icon-delete"
          :disabled="!multipleSelectionList.length"
          @click="handleBatchDelete"
        >
          {{ $t('lang.gles.common.batchDelete') }}
        </el-button>
        <!-- 列设置 -->
        <column-config :list.sync="columnConfigList" />
      </div>
      <m-table
        :table-item="tableItem"
        :table-data="tableData"
        :page-data="pageData"
        :extend-config="tableExtendConfig"
        @editItem="editItem"
        @delItem="delItem"
        @pageChange="pageChange"
        @selection-change="handleSelectionChange"
      >
        <template #clickSlot="{ row }">
          <span class="list-detail-item" @click="handleDetail(row)">
            {{ row.workPositionCode }}
          </span>
        </template>
        <template #usableFlag="{ row }">
          <el-switch
            :value="row.templateStatus"
            class="switch"
            :active-text="$t('lang.gles.common.enable')"
            :inactive-text="$t('lang.gles.common.disable')"
            :active-value="0"
            :inactive-value="1"
            :disabled="!row.nodeCount"
            @click.native="handleStateChange(row)"
          />
        </template>
      </m-table>
    </el-card>
    <!-- 详情 -->
    <detail-dialog
      v-if="detailDialogVisible"
      :visible.sync="detailDialogVisible"
      :row-detail="row"
    />
  </div>
</template>
<script>
import ColumnConfig from '@/components/columnConfig'
import QueryView from '@/components/moreQuery/queryView.vue'
import MTable from '@/libs_sz/components/MTable/MTable'
import { getMoreQueryFormData, getSearchTableItem } from '../data'
import * as types from '@/store/mutationTypes'
import listMixins from '@/mixins/listMixins'
import commonMixins from '@/mixins/commonMixins'
import { mapState } from 'vuex'
import DetailDialog from './detail.vue'

export default {
  name: 'WorkPositionList',
  components: {
    ColumnConfig,
    QueryView,
    MTable,
    DetailDialog
  },
  mixins: [listMixins, commonMixins],
  data() {
    const vm = this
    return {
      deleteMsg: vm.$t('lang.gles.workflow.workPosition.checkMsg1'),
      tableData: [],
      baseFormModel: {},
      formModel: {},
      detailDialogVisible: false,
      multipleSelectionList: [],
      // 双举升系列机器人作业模板
      doubleLiftRobotWorkTemplateList: [],
      // 悬臂系列机器人作业模板
      cantileverRobotWorkTemplateList: [],
      // 潜伏系列机器人作业模板
      lurkRobotWorkTemplateList: [],
      rollerRobotWorkTemplateList: [],
      // 晶棒机器人作业模板
      catchrobotWorkTemplateList: []
    }
  },
  computed: {
    ...mapState('workflow', ['robotWorkTypeList', 'workPositionTypeList']),
    workTemplateList() {
      return [
        ...this.doubleLiftRobotWorkTemplateList,
        ...this.cantileverRobotWorkTemplateList,
        ...this.lurkRobotWorkTemplateList,
        ...this.rollerRobotWorkTemplateList,
        ...this.catchrobotWorkTemplateList
      ]
    },
    moreQueryData() {
      return getMoreQueryFormData(this)
    },
    /**
     * 默认的表格列表项
     */
    defaultTableItem() {
      return getSearchTableItem(this)
    },
    /**
     * 渲染的表格列表项  列设置
     */
    tableItem() {
      let list = this.defaultTableItem
      if (this?.columnConfigList?.length) {
        list = this.defaultTableItem.filter(
          (item, i) =>
            item.name === this.columnConfigList[i]?.name &&
            this.columnConfigList[i]?.isShow
        )
      }
      return list
    }
  },
  async created() {
    this.tableExtendConfig = {
      ...this.tableExtendConfig,
      checkBox: true
    }
    this.tableExtendConfig.operate[1].confirmMsg = this.$t(this.deleteMsg)
    this.initSelectList()
    this.doSearch(this.formModel)
  },
  methods: {
    /**
     * 初始化下拉列表
     */
    initSelectList() {
      if (!this.robotWorkTypeList?.length) {
        this.$store.dispatch('workflow/getRobotWorkTypeList')
      }
      if (!this.workPositionTypeList?.length) {
        this.$store.dispatch('workflow/getWorkPositionTypeList')
      }
      this.getDeviceList()
      this.getProductionLineList()
      this.getAreaList()
      this.getWorkTemplateByRobotType(
        { type: 1 },
        'doubleLiftRobotWorkTemplateList'
      )
      this.getWorkTemplateByRobotType(
        { type: 2 },
        'cantileverRobotWorkTemplateList'
      )
      this.getWorkTemplateByRobotType({ type: 3 }, 'lurkRobotWorkTemplateList')
      this.getWorkTemplateByRobotType({ type: 4 }, 'rollerRobotWorkTemplateList')
      this.getWorkTemplateByRobotType({ type: 5 }, 'catchrobotWorkTemplateList')
    },
    /**
     * 根据机器人类型获取已启用作业模板
     */
    async getWorkTemplateByRobotType(params, key) {
      const { data = [], code } = await this.$httpService(
        this.$apiStore.workflow('getWorkTemplateByRobotType'),
        params
      )
      if (code) return
      const list = data || []
      this[key] = list?.map((t) => {
        return {
          label: t.templateName,
          value: t.id
        }
      })
    },
    // 新增
    updateCom(mode) {
      this.$emit('updateCom', {
        currentCom: 'WorkPositionConfigAdd',
        mode,
        row: {}
      })
    },
    /**
     * 基础查询
     */
    handleBaseSearch(data) {
      this.doSearch({ ...data })
    },
    /**
     * 更多查询
     */
    handleMoreSearch(data) {
      this.formModel = { ...data }
      this.doSearch({ ...data }, 'getWorkPositionConfigList')
    },
    /**
     * 接口查询
     */
    async doSearch(params, api = 'getWorkPositionConfigListByCodeOrName') {
      try {
        const { data, code } = await this.$httpService(
          this.$apiStore.workflow(api, this.pageData),
          params
        )
        this.$store.commit(types.IS_LOADING, false)
        if (code) return

        // 变量解构
        const {
          recordList = [],
          currentPage,
          pageSize,
          totalRecordCount: recordCount
        } = data || {}

        // 更新分页数据
        this.pageData = { currentPage, pageSize, recordCount }
        this.tableData = recordList || []
      } catch (error) {
        this.$store.commit(types.IS_LOADING, false)
      }
    },
    /**
     * 区域-产线
     */
    handleAreaChange(val, formItem) {
      if (this.formModel.productionLineId) {
        this.formModel.productionLineId = null
      }
      this.getProductionLineList({ areaId: val })
    },
    /**
     * 产线-设备
     */
    handleProductionLineChange(val, formItem) {
      if (this.formModel.deviceId) {
        this.formModel.deviceId = null
      }
      this.getDeviceList({ productionLineId: val })
    },
    /**
     * 选中记录
     */
    handleSelectionChange(val) {
      this.multipleSelectionList = val
    },
    /**
     * 批量删除
     */
    async handleBatchDelete() {
      // 二次确认
      this.$confirm(this.$t(this.deleteMsg), '', {
        confirmButtonText: this.$t('lang.gles.common.confirm'),
        cancelButtonText: this.$t('lang.gles.common.cancel'),
        type: 'warning'
      }).then(async () => {
        const delIds = this.multipleSelectionList.map((item) => item.id)
        const { code } = await this.$httpService(
          this.$apiStore.workflow('deleteWorkPositionConfig'),
          { ids: delIds }
        )
        if (code) return
        this.$message.success(this.$t('lang.gles.common.deleteSuccessfully'))
        this.doSearch(this.baseFormModel)
      })
    },
    /**
     * 查看详情
     */
    handleDetail(row) {
      this.detailDialogVisible = true
      this.row = row
      this.mode = 'detail'
    },
    // 编辑模板
    editItem({ row }) {
      this.$emit('updateCom', {
        currentCom: 'WorkPositionConfigAdd',
        mode: 'edit',
        row
      })
    },
    /**
     * 删除模版
     * 1、二次确认
     * 2、若容器类型无存在的容器档案，则删除成功，否则失败
     */
    delItem({ row }) {
      this.$httpService(this.$apiStore.workflow('deleteWorkPositionConfig'), {
        ids: [row.id]
      }).then(({ code }) => {
        if (code) return
        this.$message.success(this.$t('lang.gles.common.deleteSuccessfully'))
        if (this.tableData?.length < 2) {
          this.pageData.currentPage = 1
        }
        this.doSearch(this.baseFormModel)
      })
    }
  }
}
</script>
