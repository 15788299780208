<template>
  <div class="work-notice-wrapper">
    <el-button
      v-if="mode !== 'detail'"
      type="text"
      icon="el-icon-circle-plus"
      @click="dialogVisible = true"
    >
      {{ $t('lang.gles.workflow.addNotice') }}
    </el-button>
    <!-- 显示区 -->
    <div class="notice-list">
      <el-card
        v-for="(item, i) in innerNoticeList"
        :key="item.groupNumber"
        class="notice-card"
      >
        <!-- 编辑模式 -->
        <m-form
          ref="addForm"
          :key="item.groupNumber"
          :form-data="baseFormData(item)"
          :extend-config="getViewFormExtendConfig(item)"
          :button="button"
          label-position="right"
          :label-width="120"
          class="prop-form"
          @submit="(model) => handleEditNotice(model, item, i)"
          @reset="handleToggleEdit(i, item, false)"
        />
        <el-button-group v-if="mode !== 'detail' && !item.isEdit" class="operate-btn-wrapper">
          <el-button
            type="text"
            icon="el-icon-edit"
            @click="handleToggleEdit(i, item, true)"
          />
          <el-button
            type="text"
            icon="el-icon-delete-solid"
            class="danger"
            @click="handleDeleteNode(i)"
          />
        </el-button-group>
      </el-card>
    </div>
    <!-- 添加 -->
    <el-card v-if="isAdd" class="notice-card mt10">
      <m-form
        ref="myForm"
        :form-data="baseFormData(formModel)"
        label-position="right"
        :label-width="120"
        :extend-config="formExtendConfig"
        :button="button"
        class="basic-data-add-form"
        @submit="handleSaveNotice"
        @reset="handleReset"
      >
      </m-form>
    </el-card>
    <WorkNoticeDialog
      :visible.sync="dialogVisible"
      :checkedWorkTypes="checkedWorkTypes"
      @addNotice="handleAddNotice"
    />
  </div>
</template>
<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import commonMixins from '@/mixins/commonMixins'
import WorkNoticeDialog from './workNoticeDialog.vue'
import { getWorkNoticeFormData } from '../data'
export default {
  name: 'WorkNotice',
  mixins: [commonMixins],
  components: {
    WorkNoticeDialog
  },
  props: {
    mode: {
      type: String,
      default: 'add'
    },
    workPositionConfigId: {
      type: Number,
      default: null
    },
    noticeList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      currentNotice: {},
      dialogVisible: false,
      isAdd: false,
      viewFormExtendConfig: {
        isNeedBtn: false
      },
      formExtendConfig: {
        justify: ''
      },
      button: {
        ok: this.$t('lang.gles.common.confirm'),
        cancel: this.$t('lang.gles.common.cancel')
      },
      formModel: {},
      innerNoticeList: [],
      checkedWorkTypes: [],
      defaultNoticeInfo: [10, 11, 12, 13]
    }
  },
  computed: {
    ...mapState('workflow', [
      'noticeInfoDictList',
      'noticeTimingDictList',
      'robotWorkTypeList'
    ])
  },
  watch: {
    noticeList() {
      const list = _.cloneDeep(this.noticeList) || []
      list.forEach((t) => (t.workTypes = this.getWorkTypeName(t.workTypeList)))
      this.checkedWorkTypes = list.reduce(
        (pre, cur) => [...pre, ...(cur.workTypeList ?? [])],
        []
      )
      this.innerNoticeList = list
    }
  },
  mounted() {
    this.initSelectList()
  },
  methods: {
    initSelectList() {
      if (!this.noticeInfoDictList?.length) {
        this.$store.dispatch('workflow/getNoticeInfo')
      }
      if (!this.noticeTimingDictList?.length) {
        this.$store.dispatch('workflow/getNoticeTimingList')
      }
    },
    baseFormData(formModel) {
      return getWorkNoticeFormData(this, { ...formModel })
    },
    getViewFormExtendConfig(notice = {}) {
      return {
        isNeedBtn: notice.isEdit,
        justify: ''
      }
    },
    handleToggleEdit(i, item, isEdit) {
      this.$set(this.innerNoticeList, i, { ...item, isEdit })
    },
    /**
     * 修改通知
     */
    handleEditNotice(model, item, i) {
      model.workTypes = this.getWorkTypeName(model.workTypeList)
      this.innerNoticeList.splice(i, 1, { ...item, ...model, isEdit: false })
      this.$emit('update:noticeList', _.cloneDeep(this.innerNoticeList))
    },
    /**
     * 表单改变事件
     */
    handleFormChange(val, formItem) {
      this.$set(this.formModel, formItem.name, val)
    },
    handleAddNotice(workTypeList) {
      const workTypes = this.getWorkTypeName(workTypeList)
      this.formModel = {
        isEdit: true,
        workTypeList,
        workTypes,
        noticeStatus: 1,
        factoryPositionStatus: 1,
        groupNumber: this.innerNoticeList.length,
        noticeInfoList: [...this.defaultNoticeInfo]
      }
      this.isAdd = true
    },
    /**
     * 根据单据类型id列表返回单据类型name
     */
    getWorkTypeName(workTypeList = []) {
      let workTypes = ''
      workTypeList?.forEach((item, i) => {
        const name = this.getDictLabel(this.robotWorkTypeList, item)
        workTypes += workTypes ? `, ${name}` : `${name}`
      })
      return workTypes
    },
    handleReset() {
      this.isAdd = false
      this.formModel = {}
    },
    /**
     * 确认添加通知
     */
    async handleSaveNotice(model = {}) {
      model = _.cloneDeep(this.formModel)
      model.isEdit = false
      const targetIndex = this.innerNoticeList.findIndex(
        (t) => t.groupNumber === model.groupNumber
      )
      if (targetIndex > -1) {
        this.innerNoticeList.splice(targetIndex, 1, model)
      } else {
        this.innerNoticeList.push(model)
      }
      this.$emit('update:noticeList', _.cloneDeep(this.innerNoticeList))
      this.isAdd = false
    },
    /**
     * 删除通知
     */
    async handleDeleteNode(i) {
      // 二次确认
      this.$confirm(this.$t('lang.gles.common.deleteConfirmMsg0'), '', {
        confirmButtonText: this.$t('lang.gles.common.confirm'),
        cancelButtonText: this.$t('lang.gles.common.cancel'),
        type: 'warning'
      }).then(() => {
        this.innerNoticeList.splice(i, 1)
        this.$emit('update:noticeList', _.cloneDeep(this.innerNoticeList))
      })
    }
  }
}
</script>
<style lang="scss">
.work-notice-wrapper {
  .notice-list {
    .operate-btn-wrapper {
      display: flex;
      justify-content: flex-end;
      text-align: right;
      .el-icon-edit,
      .el-icon-delete-solid {
        margin-right: 10px;
        font-size: 14px;
      }
    }
  }
  .notice-card {
    padding: 10px;
    overflow-y: scroll;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
  }
  .Mform .el-form-item label {
    width: auto;
  }
  .basic-data-add-form {
    width: 100% !important;
  }
}
</style>
